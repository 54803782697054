import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Fixtures10 from "./Fixtures-10";
import Fixtures2 from "./Fixtures-2";
import Prize from "./Prize";

function All() {
  const [searchParams] = useSearchParams();
  const [indexShow, setIndexShow] = useState(0);
  const pages = useMemo(() => {
    return [
      { page: "fixtures-10", round: 2, element: <Fixtures10 /> },
      { page: "fixtures-2", round: 5, element: <Fixtures2 /> },
      { page: "prize", round: 5, element: <Prize /> },
    ];
  }, []);
  const pageSize = useMemo(() => pages.length, [pages]);
  const page = useMemo(() => get(pages, indexShow, null), [indexShow, pages]);
  const time = useMemo(
    () => parseInt(searchParams.get("second") ?? "10") * 1000,
    [searchParams]
  );

  useEffect(() => {
    if (page) {
      const timer = setTimeout(() => {
        setIndexShow((old) => (old + 1) % pageSize);
      }, time * (page.round ?? 0));
      return () => {
        clearTimeout(timer);
      };
    }
  }, [time, page, pageSize]);

  return page?.element ?? null;
}

export default All;
