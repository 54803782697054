import {
  ElementType,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

export default function AnimationNumber(props: {
  value: number | string;
  as?: ElementType;
}): ReactElement {
  const [value, setValue] = useState<number>(0);
  const timerRef = useRef<NodeJS.Timeout>(null);
  const animate = useCallback(
    (oldValue: number | undefined, target: number, step: number) => {
      const newValue = (oldValue ?? 0) + step;
      if (oldValue && Math.abs(newValue - target) >= Math.abs(step)) {
        setValue(newValue);
        timerRef.current = setTimeout(() => animate(newValue, target, step), 1);
      } else {
        setValue(target);
      }
    },
    []
  );
  useEffect(() => {
    const oldValue = value ?? 0;
    const target =
      typeof props.value === "string" ? parseFloat(props.value) : props.value;
    const diff = target - oldValue;
    const diffAbs = Math.abs(diff);
    const time = diffAbs < 500 ? 10 : diffAbs < 5000 ? 100 : 200;
    animate(oldValue, target, diff / time);
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);
  return (
    <>
      {Math.floor(value)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    </>
  );
}
