import React, { useCallback, useEffect, useMemo } from "react";
import Table from "./Table";
import { chunk, get, map } from "lodash";
import { useSelector } from "react-redux";
import { getPlayerMappingByPlayerId } from "../store/playerSlice";
import { selectGroupByRound } from "../store/groupSlice";
import { selectStatGroupByPlayerByRound } from "../store/betStatsSlice";
import { makeFixturesData } from "../utils";
import { useSearchParams } from "react-router-dom";

function Fixtures10() {
  const [searchParams] = useSearchParams();
  const groupsFirst = useSelector((state: any) =>
    selectGroupByRound(state, "first")
  );
  const playersMapping = useSelector(getPlayerMappingByPlayerId);
  const statByPlayer = useSelector((state: any) =>
    selectStatGroupByPlayerByRound(state, "first")
  );

  const cols = useMemo(() => {
    return [
      {
        key: "name",
        col: 7,
        format: (val: string | number) => (
          <div className="d-flex gap-1 align-items-center">
            <img
              alt="profile"
              src="/assets/profile-user.svg"
              width={24}
              height={24}
              loading="eager"
            />
            <div>{val}</div>
          </div>
        ),
      },
      {
        key: "point",
        col: 4,
        format: (val: string | number) => `${val} คะแนน`,
        className: "justify-content-end",
      },
    ];
  }, []);
  const groups = useMemo(() => {
    return makeFixturesData(groupsFirst, playersMapping, statByPlayer);
  }, [groupsFirst, playersMapping, statByPlayer]);
  const changeShowGroup = useCallback(() => {
    const groupTableEle = document.getElementById("group-table");
    if (groupTableEle) {
      const groupShow = groupTableEle.querySelector("&> div:not(.d-none)");
      const indexShow = Array.prototype.indexOf.call(
        groupTableEle.children,
        groupShow
      );
      groupShow?.classList.toggle("d-none", true);
      setTimeout(() => {
        get(
          groupTableEle.children,
          (indexShow + 1) % groupTableEle.childElementCount
        )?.classList.toggle("d-none", false);
      }, 200);
    }
  }, []);
  const page = useMemo(
    () => parseInt(searchParams.get("page") ?? "0"),
    [searchParams]
  );
  useEffect(() => {
    const page = searchParams.get("page");
    if (!page) {
      const timer = setInterval(
        changeShowGroup,
        parseInt(searchParams.get("second") ?? "10") * 1000
      );
      return () => {
        clearInterval(timer);
      };
    }
  }, [changeShowGroup, searchParams]);
  return (
    <div className="position-relative mx-4 py-3">
      <div className="position-absolute start-0 bottom-100 page-type">
        FIXTURES
      </div>
      <div className="round-status">Group Stage</div>
      <div id="group-table" className="mt-3">
        {map(chunk(groups, 10), (groupsChunk, chunkIndex) => (
          <div
            key={chunkIndex}
            className={`row row-cols-5 g-3 mt-0 ${
              (page ? page - 1 !== chunkIndex : chunkIndex) ? "d-none" : ""
            }`}
            style={{ fontSize: "1.125rem" }}
          >
            {map(groupsChunk, (group, index) => (
              <div key={index} className="col mt-0">
                <Table header={group.name} items={group.users} cols={cols} />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Fixtures10;
