import { get } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import All from "./All";
import Iframe from "./Iframe";

function GroupStage() {
  const [searchParams] = useSearchParams();
  const [indexShow, setIndexShow] = useState(0);
  const pages = useMemo(() => {
    return [
      {
        page: "iframe",
        round: 3,
        element: (
          <Iframe src="https://docs.google.com/presentation/d/e/2PACX-1vSj3Fjcalff0IroooHgW0lm30IQxLXX3BKh2EomIpg9rStwAUgtQiFzBe-Zoc9HGi_4Fjr2spUBDoe1/embed" />
        ),
      },
      { page: "all", round: 12, element: <All /> },
    ];
  }, []);
  const pageSize = useMemo(() => pages.length, [pages]);
  const page = useMemo(() => get(pages, indexShow, null), [indexShow, pages]);
  const time = useMemo(
    () => parseInt(searchParams.get("second") ?? "10") * 1000,
    [searchParams]
  );

  useEffect(() => {
    if (page) {
      const timer = setTimeout(() => {
        setIndexShow((old) => (old + 1) % pageSize);
      }, time * (page.round ?? 0));
      return () => {
        clearTimeout(timer);
      };
    }
  }, [time, page, pageSize]);

  return page?.element ?? null;
}

export default GroupStage;
