import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { query, collection, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { UserBetStatByShoe } from "../types";
import { AppDispatch } from ".";

interface BetStat {
  betStats: UserBetStatByShoe[];
  loading: boolean;
  error: string | null;
}

// Initial State
const initialState: BetStat = {
  betStats: [],
  loading: false,
  error: null,
};

// Async Thunk get all user bet stat
export const fetchUserBetStat = createAsyncThunk(
  "betStat/fetchUserBetStat",
  async (_, { rejectWithValue }) => {
    try {
      const userBetStatsQuery = query(collection(db, "userShoeBetStats"));
      const userBetStatsSnapshot = await getDocs(userBetStatsQuery);
      const userBetStats: UserBetStatByShoe[] = [];
      userBetStatsSnapshot.forEach((doc) => {
        userBetStats.push({ id: doc.id, ...doc.data() } as UserBetStatByShoe);
      });

      return userBetStats;
    } catch (error) {
      return rejectWithValue("Failed to fetch user bet stats");
    }
  }
);

export const subscribeToUserBetStat = () => (dispatch: AppDispatch) => {
  const userBetStatsQuery = query(collection(db, "userShoeBetStats"));

  // Subscribe to real-time updates
  const unsubscribe = onSnapshot(userBetStatsQuery, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      const stat = {
        id: change.doc.id,
        ...change.doc.data(),
      } as UserBetStatByShoe;

      if (change.type === "added") {
        dispatch(addStat(stat));
      } else if (change.type === "modified") {
        dispatch(updateStat(stat));
      } else if (change.type === "removed") {
        if (stat.id) {
          dispatch(removeStat(stat.id));
        }
      }
    });
  });

  return unsubscribe; // Return the unsubscribe function for cleanup
};

const betStatsSlice = createSlice({
  name: "betStats",
  initialState,
  selectors: {
    selectBetStats: (state) => state.betStats,
    selectLoading: (state) => state.loading,
    selectError: (state) => state,
    selectStatGroupByPlayerByRound: (state, round: string) => {
      const groupByPlayer: { [key: string]: UserBetStatByShoe[] } = {};
      state.betStats.forEach((stat) => {
        if (stat.round === round && stat.status === "ended") {
          if (!groupByPlayer[stat.playerId]) {
            groupByPlayer[stat.playerId] = [];
          }
          groupByPlayer[stat.playerId].push(stat);
        }
      });
      return groupByPlayer;
    },
    selectStatGroupByPlayerByRound2: (state, round: string) => {
      const groupByPlayer: { [key: string]: UserBetStatByShoe[] } = {};

      state.betStats.forEach((stat) => {
        if (stat.round === round) {
          if (!groupByPlayer[stat.playerId]) {
            groupByPlayer[stat.playerId] = [];
          }
          groupByPlayer[stat.playerId].push(stat);
        }
      });
      return groupByPlayer;
    },
    selectStatGroupByPlayer: (state) => {
      const groupByPlayer: { [key: string]: UserBetStatByShoe[] } = {};
      state.betStats.forEach((stat) => {
        if (stat.status === "ended") {
          if (!groupByPlayer[stat.playerId]) {
            groupByPlayer[stat.playerId] = [];
          }
          groupByPlayer[stat.playerId].push(stat);
        }
      });
      return groupByPlayer;
    },
  },
  reducers: {
    setBetStats(state, action: PayloadAction<UserBetStatByShoe[]>) {
      state.betStats = action.payload;
    },
    addStat(state, action: PayloadAction<UserBetStatByShoe>) {
      state.betStats.push(action.payload);
    },
    updateStat(state, action: PayloadAction<UserBetStatByShoe>) {
      const index = state.betStats.findIndex(
        (stat) => stat.id === action.payload.id
      );
      if (index !== -1) {
        state.betStats[index] = action.payload;
      }
    },
    removeStat(state, action: PayloadAction<string>) {
      state.betStats = state.betStats.filter(
        (stat) => stat.id !== action.payload
      );
    },
    clearState(state) {
      state.betStats = [];
      state.loading = false;
      state.error = null;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUserBetStat.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchUserBetStat.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.betStats = action.payload;
    });
    builder.addCase(fetchUserBetStat.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { setBetStats, addStat, updateStat, removeStat, clearState } =
  betStatsSlice.actions;

export const {
  selectBetStats,
  selectLoading,
  selectError,
  selectStatGroupByPlayerByRound,
  selectStatGroupByPlayerByRound2,
  selectStatGroupByPlayer,
} = betStatsSlice.selectors;

export default betStatsSlice.reducer;
