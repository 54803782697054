import { useEffect, useState } from "react";
import Iframe from "./Iframe";
import { useSearchParams } from "react-router-dom";

function Before() {
  const [searchParams] = useSearchParams();
  const [show, setShow] = useState(true);
  useEffect(() => {
    const timer = setInterval(() => {
      setShow(false);
      setTimeout(() => setShow(true), 200);
    }, parseInt(searchParams.get("second") ?? "10") * 1000 * 4 * 5);
    return () => {
      clearInterval(timer);
    };
  }, [searchParams]);
  return show ? (
    <Iframe src="https://docs.google.com/presentation/d/e/2PACX-1vS7lSlmiAXeayGWOrhZprT9sn6fTbX1ZdHydEVVNJX3kWExYfgGX0QqJdEmTwOUcHXjB1iFSuTEMEdG/embed" />
  ) : null;
}

export default Before;
